import { GET_REPORT_FAIL, GET_REPORT_SUCCESS } from "../constants/reportConstants";
import { RESET_ERROR } from "../constants/userConstants";

const initialState = {
    reportList: [], // Initialize as empty array
    error: null,
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,
            };
        case GET_REPORT_SUCCESS:
            console.log({ payload: action.payload });
            return {
                ...state,
                reportList: action.payload,
            };
        case GET_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
