import { Disc } from "react-feather"
import axios from "../../customAxios"
import {
    CREATE_STORE_SUCCESS,
    CREATE_STORE_FAIL,
    GET_STORE_SUCCESS,
    GET_STORE_FAIL,
    DEL_STORE_SUCCESS,
    DEL_STORE_FAIL,
    UPDATE_STORE_STATUS_SUCCESS,
    UPDATE_STORE_STATUS_FAIL,
    // EDIT_STORE_SUCCESS,
    // EDIT_STORE_FAIL,
    UPDATE_STORE_FAIL,
    UPDATE_STORE_SUCCESS
} from "../constants/storeConstants"
import { LOADING } from "../constants/userConstants"


//*............................STORE..............................................................

export const CreateStore = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`/admin/store`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_STORE_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {

        //console.log(err.message)
        dispatch({
            type: CREATE_STORE_FAIL,
            payload: err
        })
    }))

}

export const UpdateStoreStatus = (data) => async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true
    });
  
    try {
      const response = await axios.post(`/admin/store-status`, data);
      let updatedStore = response.data.data; // Assuming this is the complete store object
  
      dispatch({
        type: UPDATE_STORE_STATUS_SUCCESS,
        payload: updatedStore
      });
  
      dispatch({
        type: LOADING,
        payload: false
      });
    } catch (err) {
      dispatch({
        type: UPDATE_STORE_STATUS_FAIL,
        payload: err
      });
    }
  };

export const UpdateStoreManagement = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`/admin/store-edit`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: UPDATE_STORE_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {

        //console.log(err.message)
        dispatch({
            type: UPDATE_STORE_FAIL,
            payload: err
        })
    }))

}


export const GetStore = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/store`).then(async response => {
        //console.log(response, "response data")
        let data = response.data.data;

        dispatch({
            type: GET_STORE_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {
        dispatch({
            type: GET_STORE_FAIL,
            payload: err
        })
    }))

}

export const DeleteStore = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/store/${id}`).then(async response => {
        dispatch({
            type: DEL_STORE_SUCCESS,
            payload: id
        });
        dispatch({
            type: LOADING,
            payload: false
        });

    }).catch(err => {
        dispatch({
            type: LOADING,
            payload: false
        });
        dispatch({
            type: DEL_STORE_FAIL,
            payload: err
        })
    })


}


// export const UpdateStore = (data) => async (dispatch) => {
//     dispatch({
//         type: LOADING,
//         payload: true
//     })
//     await axios.post(`/admin/edit-category`, data).then(async responce => {
//         dispatch({
//             type: EDIT_STORE_SUCCESS,
//             payload: responce.data.data
//         })
//         dispatch({
//             type: LOADING,
//             payload: false
//         })
//     }).catch(err => {
//         dispatch({
//             type: EDIT_STORE_FAIL,
//             payload: err
//         })
//     })
// }