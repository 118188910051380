import { UPDATE_ORDER_SUCCESS } from "../constants/orderConstants";
import {
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  GET_STORE_SUCCESS,
  GET_STORE_FAIL,
  DEL_STORE_SUCCESS,
  UPDATE_STORE_STATUS_SUCCESS,
  UPDATE_STORE_STATUS_FAIL,
  // EDIT_STORE_SUCCESS,
  // EDIT_STORE_FAIL,
  DEL_STORE_FAIL,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
} from "../constants/storeConstants";
import { RESET_ERROR } from "../constants/userConstants";

export const storeReducer = (state = { error: null }, action) => {
  switch (action.type) {
    case RESET_ERROR:
      return {
        ...state,
        error: null,

        newstore: null,
        storedelsuccess: null,
        editedstore: null,
        updatedstore: null,
      };

    //....................................STORE...............................

    case GET_STORE_SUCCESS:
      return {
        ...state,
        getstorelist: action.payload,
      };
    case CREATE_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        getstorelist: state?.getstorelist
          ? [...state.getstorelist, action.payload]
          : [action.payload],
        newstore: true,
      };
      case GET_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
      case UPDATE_STORE_STATUS_SUCCESS:
      return {
        // getstorelist: state?.getstorelist.map((store) =>
        //   store._id === action.payload._id
        //     ? { ...store, status: action.payload.status }
        //     : store
        // ),
        ...state,
        getstorelist: state.getstorelist.map((store) =>
          store._id === action.payload._id ? { ...store, ...action.payload } : store
        ),
      };

    case UPDATE_STORE_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DEL_STORE_SUCCESS:
      return {
        ...state,
        getstorelist: state?.getstorelist.filter(
          (unit) => unit._id !== action.payload
        ),
        storedelsuccess: true,
      };

    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        updatedstore: true,
        // getstorelist: state?.getstorelist
        //   ? [...state.getstorelist, action.payload]
        //   : [action.payload],
          getstorelist: state?.getstorelist.map((sto) =>
                  sto._id === action.payload._id ? action.payload : sto
                ),
      };

      case UPDATE_STORE_FAIL:
        return {
          ...state,
          error: action.payload,
        };

    case DEL_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    // case EDIT_STORE_SUCCESS:
    //   return {
    //     ...state,
    //     getstorelist: state?.getstorelist.map((sto) =>
    //       sto._id === action.payload._id ? action.payload : sto
    //     ),
    //     editedstore: true,
    //   };
    // case EDIT_STORE_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   };

    default:
      return state;
  }
};
