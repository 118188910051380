import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment';
const Editunitmodal = ({ openModal, onCloseModal, editdata, variant, setVariantsData, func }) => {

    // console.log(editdata, "daoas")


    const [offer, setOffer] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const schema = yup.object({
        name: yup.string().required('Variant Name field is required'),
        Regular_Price: yup.number()
            .transform((value, originalValue) => {
                // If the original value is an empty string, set the value to undefined
                return originalValue === '' ? undefined : value;
            })
            .nullable()  // This allows the field to be null or undefined
            .notRequired(),  // This makes the field optional
        Selling_Price: yup.number().required('Selling price is required').typeError('Selling price is required'),
        //unit_id:yup.string().required("Please select unit") ,
        // Offer_Price:yup.string().test(
        //     'Is positive?', 
        //     ' The number must be greater than 0!', 
        //     (value) => value > 0
        //   )

    }).required();

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),

    });

    useEffect(() => {
        if (editdata) {

            console.log(typeof parseInt(editdata?.stock));

            // console.log({ editdata })

            if (editdata?.offer) {
                setOffer(true)

                setStartDate(editdata?.fromDate ? moment(editdata?.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null)
                setEndDate(editdata?.toDate ? moment(editdata?.toDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null)
                setValue("startDate", editdata?.fromDate ? moment(editdata?.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null)
                setValue("EndDate", editdata?.toDate ? moment(editdata?.toDate, "YYYY-MM-DD").format("YYYY-MM-DD") : null)
            }
            setValue("name", editdata?.name)
            setValue("Variant_Description", editdata?.description)
            setValue("Selling_Price", editdata?.selling ? editdata?.selling : "")
            setValue("Regular_Price", editdata?.regular ? editdata?.regular : "")
            setValue("stock", editdata?.stock ? parseInt(editdata?.stock) ? String(editdata?.stock) : '0' : null)
            setValue("weight", editdata?.weight ? editdata?.weight : null)
            setValue("height", editdata?.height ? editdata?.height : null)
            setValue("length", editdata?.length ? editdata?.length : null)
            setValue("Offer_Price", editdata?.offer)
            setValue("unit", editdata?.unit)
        }

    }, [editdata, variant])

    const Editform = (event) => {


        const val = {
            uid: editdata.uid,
            id: editdata.id,
            name: event.name,
            description: event.Variant_Description,
            selling: event.Selling_Price ? event.Selling_Price : "",
            regular: event.Regular_Price ? event.Regular_Price : "",
            stock: event.stock ? String(event.stock) : null,
            weight: event.weight ? event.weight : null,
            height: event.height ? event.height : null,
            length: event.length ? event.length : null,
            offer: event?.Offer_Price ? event?.Offer_Price : null,
            fromDate: event.startDate,
            toDate: event.EndDate
        };

        // console.log({val})
        // return false;

        func(val)

        // const result = variant.filter((res) => res.uid !== val.uid)
        // setVariantsData([...result, val])
        onCloseModal()

    }

    const offerselect = (e, checked) => {

        if (checked) {
            setValue("startDate", null)
            setValue("EndDate", null)
            setOffer(true)
        } else {
            setValue("Offer_Price", "")
            setOffer(false)
        }
    }


    // return

    return (
        <Modal isOpen={openModal} toggle={onCloseModal}>
            <ModalHeader toggle={onCloseModal}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    Edit Product
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>


                    <div>

                        <CustomInput
                            fieldLabel={'Variant Name:'}
                            control={control}
                            error={errors.name}
                            fieldName={'name'}
                        />
                    </div>
                    {/* <div>

                        <CustomInput
                            fieldLabel='Variant Description'
                            control={control}
                            error={errors.Variant_Description}
                            fieldName={'Variant_Description'}
                        />
                    </div> */}

                    <Row xl="2">



                        <CustomInput
                            fieldLabel='Normal Price'
                            control={control}
                            error={errors.Selling_Price}
                            fieldName={'Selling_Price'}
                            type="number"
                        />
                        <CustomInput
                            fieldLabel='Selling Price'
                            control={control}
                            error={errors.Regular_Price}
                            fieldName={'Regular_Price'}
                            type="number"
                        />
                    </Row>

                    <Row xl="2">

                        <CustomInput
                            fieldLabel='Stock'
                            control={control}
                            error={errors.stock}
                            fieldName={'stock'}
                            type="number"
                        />
                        {/* <CustomInput
                            fieldLabel='Weight(gm)'
                            control={control}
                            error={errors.weight}
                            fieldName={'weight'}
                            type="number"
                        /> */}
                    </Row>
                    {/* <Row xl="2">

                        <CustomInput
                            fieldLabel='Height(cm)'
                            control={control}
                            error={errors.height}
                            fieldName={'height'}
                            type="number"
                        />
                        <CustomInput
                            fieldLabel='Length(cm)'
                            control={control}
                            error={errors.length}
                            fieldName={'length'}
                            type="number"
                        />
                    </Row> */}

                    {/* <div style={{ display: "flex", alignItems: "center", gap: "10px", }}>
                        <input type="checkbox" checked={offer} onChange={(e) => offerselect(e.target.value, e.target.checked)} style={{ cursor: "pointer" }}></input>
                        <label>Add Offer Price</label>

                    </div> */}
                    {offer && <div>


                        <CustomInput
                            fieldLabel='Offer Price'
                            control={control}
                            error={errors.Offer_Price}
                            fieldName={'Offer_Price'}
                            type="number"
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>


                            <CustomInput
                                fieldLabel={'Start Date'}
                                control={control}
                                error={errors.startDate}
                                defaultValue={startDate}
                                fieldName={'startDate'}
                                type="date"
                                nin={moment().format("YYYY-MM-DD")}
                            />

                            <CustomInput
                                fieldLabel={'End Date'}
                                control={control}
                                error={errors.EndDate}
                                fieldName={'EndDate'}
                                defaultValue={"2024-07-15"}
                                type={'date'}
                            />

                        </div>



                    </div>}

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(Editform)}

                >
                    Save
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Editunitmodal