export const CREATE_STORE_SUCCESS = "CREATESTORESUCCESS"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL"
export const CREATE_STORE_FAIL = "CREATESTOREFAIL"
export const UPDATE_STORE_STATUS_SUCCESS = "UPDATE_STORE_STATUS_SUCCESS"
export const UPDATE_STORE_STATUS_FAIL = "UPDATE_STORE_STATUS_FAIL"
export const GET_STORE_SUCCESS = "GETSTORESUCCESS"
export const GET_STORE_FAIL = "GETSTOREFAIL"
export const DEL_STORE_SUCCESS = "DELSTORESUCCESS"
export const DEL_STORE_FAIL = "DELSTOREFAIL"
// export const EDIT_STORE_SUCCESS = "EDITSTORESUCCESS"
// export const EDIT_STORE_FAIL = "EDITSTOREFAIL"