
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import DataTables from "../common/DataTables";
import { useDispatch, useSelector } from "react-redux";
import { GetCustomerList } from "../../redux/actions/SettingActions";
import SearchHeader from "../common/header_components/searchHeader";

const CustomerList = () => {
    const dispatch = useDispatch()

    const { CustomerListdata } = useSelector(state => state.settings)
    // console.log(CustomerListdata,"CustomerListdata")
    const [data, setData] = useState([])
    const [filterText, setFilterText] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(false);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1,
            maxWidth: '100px'
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            maxWidth: '250px',
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email")}</b>,
            header: <b>{Capitalize("Email")}</b>,
            selector: row => row?.email,
            maxWidth: '350px',
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Address")}</b>,
            header: <b>{Capitalize("Address")}</b>,
            selector: (row) => (
                <>
                    {row?.address ? (<span>
                        {row?.address?.doorNumber + ", " + row?.address?.streetName + ", " + row?.address?.cityName + ", " + row?.address?.country + ", " + row?.address?.postcode},
                    </span>) : "-"}
                </>

            ),
            maxWidth: '460px',
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Mobile (+44)")}</b>,
            header: <b>{Capitalize("Mobile (+44)")}</b>,
            selector: row => row?.mobile,
            maxWidth: '250px',
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            maxWidth: '150px',
            style: {
                textAlign: "center",
            },
        },

    ];

    useEffect(() => {
        fetchList();
    }, [])

    useEffect(() => {
        if (CustomerListdata) {
            setData(CustomerListdata)
            setOriginalData(CustomerListdata);
        }
    }, [CustomerListdata])

    const fetchList = async () => {
        setLoading(true); // Start loading
        await dispatch(GetCustomerList())
        setLoading(false); // Stop loading
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
                setData(originalData);
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText, originalData]);

    useEffect(() => {
        if (filterText) {
            const filteredData = originalData.filter(sto =>
                sto?.name?.toLowerCase().includes(filterText.toLowerCase()) ||
                sto?.mobile?.toString().toLowerCase().includes(filterText.toLowerCase())
            );
            setData(filteredData);
        } else {
            setData(originalData); // Reset to original data if no filter text
        }
    }, [filterText, originalData]);

    return (
        <Fragment>
            <Breadcrumb title="Customer" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Customer List</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        originalData && originalData?.length > 0 ? (
                                            <DataTables
                                                columns={columns}
                                                row={data}
                                                pagination={true}
                                                noRowsPerPage={10}
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                            />
                                        ) : (
                                            <p>No data available.</p>
                                        )
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    )
}

export default CustomerList