import React, { Fragment, useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import ModalNotification from './ModalNotification';
import { useDispatch, useSelector } from "react-redux";
import { DELNOTIFICATION, GETNOTIFICATION } from '../../../redux/actions/SettingActions';
import DataTables from '../../common/DataTables';
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import customAxios from '../../../customAxios';
const AddNotification = () => {

    const dispatch = useDispatch();

    const { NotificationList, notificationcreated, notificationdeleted, Notificationupdated } = useSelector(state => state.settings)

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [product, setProduct] = useState([]);
    const [category, setCategory] = useState([]);
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)

    const customId = "delNos";

    const onOpenModal = () => {
        setOpen(true);
    };


    const handleEdit = (data) => {
        setActiveData(data)
        setOpen(true);
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DELNOTIFICATION(id))
        }
    }


    const getProductCategory = async () => {
        try {
            const { data: product } = await customAxios.get('admin/marketing/product_list');
            setProduct(product?.data?.map((res) => ({ value: res?._id, label: res?.name })));
            const { data: category } = await customAxios.get('admin/marketing/category_list');
            setCategory(category?.data?.map((res) => ({ value: res?._id, label: res?.name })))

        } catch (err) {

        }
    }



    useEffect(() => {
        getProductCategory()
    }, []);


    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        <img style={{ width: 40, height: 40 }} src={row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Notification Name ")}</b>,
            header: <b>{Capitalize("Notification Name ")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Description")}</b>,
            header: <b>{Capitalize("description")}</b>,
            selector: row => row?.description,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Type")}</b>,
            header: <b>{Capitalize("Type")}</b>,
            selector: row => row?.type,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    {/* <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span> */}
                </>
            )
        }
    ];



    useEffect(() => {
        dispatch(GETNOTIFICATION())
    }, [])

    useEffect(() => {
        if (notificationcreated) {
            toast.success("Notification Created successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [notificationcreated])


    useEffect(() => {
        if (notificationdeleted) {
            toast.success("Notification Deleted successfully",{toastId: customId});
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [notificationdeleted])

    useEffect(() => {
        if (Notificationupdated) {
            toast.success("Notification Updated successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [Notificationupdated])



    return (
        <Fragment>
            <Breadcrumb title="Notification" parent="settings" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Notification</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add Notification
                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {NotificationList && <DataTables
                                        columns={columns}
                                        row={NotificationList ? NotificationList : []}
                                    />}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalNotification
                product={product}
                category={category}
                openModal={open}
                onCloseModal={() => {
                    setActiveData(null)
                    setOpen(false)
                }} data={activeData} setActiveData={setActiveData}
            />


        </Fragment>
    )
}

export default AddNotification