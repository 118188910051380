import axios from "../../customAxios";
import { GET_REPORT_FAIL, GET_REPORT_SUCCESS } from "../constants/reportConstants";
import { LOADING } from "../constants/userConstants";

export const getReportList = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    try {
        const response = await axios.get(`/admin/reports`);
        const data = response.data.data;
        dispatch({
            type: GET_REPORT_SUCCESS,
            payload: data,
        });
    } catch (err) {
        dispatch({
            type: GET_REPORT_FAIL,
            payload: err,
        });
    } finally {
        dispatch({
            type: LOADING,
            payload: false,
        });
    }
};

export const filterReport = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    try {
        const response = await axios.post(`/admin/reports-filter`, data);
        const filteredData = response.data.data;
        dispatch({
            type: GET_REPORT_SUCCESS,
            payload: filteredData,
        });
    } catch (err) {
        dispatch({
            type: GET_REPORT_FAIL,
            payload: err,
        });
    } finally {
        dispatch({
            type: LOADING,
            payload: false,
        });
    }
};
