import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  CardBody,
  Button,
  CardHeader,
  Row,
  Table,
} from "reactstrap";
// import AddDeliverySlotModal from './AddDeliverySlotModal';
import DataTable from "react-data-table-component";
import customAxios from "../../../customAxios";
import { toast } from "react-toastify";
import EditDeliveryChargeModal from "./EditDeliveryChargeModal";
import { useDispatch, useSelector } from "react-redux";
import { GetDeliveryCharge } from "../../../redux/actions/SettingActions";

const DeliveryCharges = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { delivery_data } = useSelector((state) => state?.settings);

  useEffect(() => {
    dispatch(GetDeliveryCharge());
  }, []);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = useCallback(() => {     
      dispatch(GetDeliveryCharge());
      setOpen(false);
   }, [])


  return (
    <Fragment>
      <Breadcrumb title="Delivery Charges" parent="Settings" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Delivery Charges</h5>
              </CardHeader>
              <CardBody>
                <div className="btn-popup pull-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={onOpenModal}
                    data-toggle="modal"
                    data-original-title="test"
                    data-target="#exampleModal"
                  >
                    {delivery_data?.length > 0 ? 'EDIT' : 'CREATE'} DELIVERY CHARGE
                  </Button>
                </div>

                {delivery_data?.length > 0 && (
                  <div className="tab-pane fade show active">
                    <div className="table-responsive profile-table">
                      <Table className="table-responsive">
                        <tbody>
                          <tr>
                            <td>Normal Weight: </td>
                            <td>{delivery_data?.[0]?.normal_weight}</td>
                          </tr>
                          <tr>
                            <td>Normal KM: </td>
                            <td>{delivery_data?.[0]?.normal_km}</td>
                          </tr>
                          <tr>
                            <td>Extra Weight Rate: </td>
                            <td>{delivery_data?.[0]?.extra_weight_charge}</td>
                          </tr>
                          <tr>
                            <td>Extra KM Rate: </td>
                            <td>{delivery_data?.[0]?.extra_charge_per_km}</td>
                          </tr>
                          <tr>
                            <td>Normal Delivery Charge: </td>
                            <td>{delivery_data?.[0]?.normal_charge}</td>
                          </tr>

                          {/* <tr>
								<td>DOB:</td>
								<td>Dec, 15 1993</td>
							</tr>
							<tr>
								<td>Location:</td>
								<td>USA</td>
							</tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {open && (
        <EditDeliveryChargeModal
          openModal={open}
          data={delivery_data?.length > 0 ? delivery_data?.[0] : {}}
          onCloseModal={onCloseModal}
          title={"Add Time Slot"}
        />
      )}
    </Fragment>
  );
};

export default DeliveryCharges;
