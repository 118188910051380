import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from '../common/CustomTextArea';
import ImageInput from '../common/ImageInput';
import CustomInput from '../common/CustomInput';
import ImagesInput from '../common/ImagesInput';
import axios from 'axios';
import customAxios from '../../customAxios';
import { toast } from 'react-toastify';
const ViewTopic = ({ openModal, onCloseModal, id }) => {
    
   const [loading, setLoading] = useState(false)

   const [topicDetails, setTopicDetails] = useState({})

    useEffect(() => {
        getTopicDetails(id)
    }, [id])

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm();


    const getTopicDetails = async() => {

        setLoading(true)
        let topic = await customAxios.get(`admin/topic/${id}`);

        let datas = topic?.data?.data
        setTopicDetails(datas)

        let data = {
            name: datas?.name
        }


        // console.log({data})

        reset(data)
    }
    


    const clearclose = () => {
        setValue("name", "")
        onCloseModal()

    }

    return (

        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    View Topic
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <CustomInput
                        fieldLabel='Name :'
                        control={control}
                        error={errors.name}
                        fieldName='name'
                        readOnly={true}
                    />
                </Form>
            </ModalBody>
        </Modal>

    )
}

export default ViewTopic