import axios from "../../customAxios"
import { ADD_POST_CODE, ADD_POST_CODE_ERR, CREATE_Notification_FAIL, CREATE_Notification_SUCCESS, CREATE_PROFILE_FAIL, CREATE_PROFILE_SUCCESS, CREATE_SLIDER_FAIL, CREATE_SLIDER_SUCCESS, DEL_Notification_FAIL, DEL_Notification_SUCCESS, DEL_SLIDER_FAIL, DEL_SLIDER_SUCCESS, DELETE_POST_CODE,DELETE_POST_CODE_FAIL, DELIVERY_CHARGE_FAILED, DELIVERY_CHARGE_SUCCESS, DELIVERY_CHARGE_UPDATED, GET_CUSTOMER_LIST_FAIL, GET_CUTOMER_LIST_SUCCESS, GET_Notification_FAIL, GET_Notification_SUCCESS, GET_POST_CODES, GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, GET_SLIDER_FAIL, GET_SLIDER_SUCCESS, SEARCH_MASTER_SUCCESS, UPDATE_Notification_FAIL, UPDATE_Notification_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS, UPDATE_SLIDER_FAIL, UPDATE_SLIDER_SUCCESS, USER_MANAGEMENT_DELETE, USER_MANAGEMENT_DELETE_FAILED, USER_MANAGEMENT_GET_LIST, USER_MANAGEMENT_GET_LIST_FAILED, USER_MANAGEMENT_STATUS_UPDATED } from "../constants/settingConstants"
import { LOADING } from "../constants/userConstants"


export const CREATEPROFILE = (data) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-setting`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_PROFILE_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: CREATE_PROFILE_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const GETPROFILE = () => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/settings`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_PROFILE_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_PROFILE_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const EDITSETTING = (data) => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-setting`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: UPDATE_PROFILE_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}


/******************************NOTIFICATION************************************** */

export const CREATENOTIFICATION = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-notification`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_Notification_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: CREATE_Notification_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const GETNOTIFICATION = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/notifications1`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_Notification_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_Notification_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const DELNOTIFICATION = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/notification/${id}`).then(async response => {
        dispatch({
            type: DEL_Notification_SUCCESS,
            payload: id
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: DEL_Notification_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const EDITNOTIFICATION = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-notification`, data).then(async response => {
        dispatch({
            type: UPDATE_Notification_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: UPDATE_Notification_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}


/********************SLIDER******************************* */

export const GetSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/sliders`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_SLIDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_SLIDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const PostSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-slider`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_SLIDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:CREATE_SLIDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const delSlider = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/slider/${id}`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: DEL_SLIDER_SUCCESS,
            payload: id
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:DEL_SLIDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const UpdateSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-slider`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: UPDATE_SLIDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:UPDATE_SLIDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}
//******************************CUSTOMER********************************** */

export const GetCustomerList = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/customers`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_CUTOMER_LIST_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:GET_CUSTOMER_LIST_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}


//******************************DELIVERY_CHARGE********************************** */
export const GetDeliveryCharge = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/chargelist`).then(async response => {
        let data = response.data.data;        
        dispatch({
            type: DELIVERY_CHARGE_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: DELIVERY_CHARGE_FAILED,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}


export const CreateDeliveryCharge = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-charge`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: DELIVERY_CHARGE_UPDATED,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:DELIVERY_CHARGE_FAILED,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}


export const EditDeliveryCharge = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/create-charge`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: DELIVERY_CHARGE_UPDATED,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:DELIVERY_CHARGE_FAILED,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

 
}


//******************************USER_MANAGEMENT********************************** */

export const GetUsermanangementList = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/userlist`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: USER_MANAGEMENT_GET_LIST,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: USER_MANAGEMENT_GET_LIST_FAILED,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}


export const DeleteManager = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`admin/delete-manager/` + data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: USER_MANAGEMENT_DELETE,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: USER_MANAGEMENT_DELETE_FAILED,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}

export const UpdateUserManagementStatus = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/update-status`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: USER_MANAGEMENT_STATUS_UPDATED,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        // dispatch({
        //     type: usemana,
        //     payload: err
        // })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}

export const GetPostCodes = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`admin/postcodelist`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_POST_CODES,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        // dispatch({
        //     type: usemana,
        //     payload: err
        // })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}

export const DeletePostCode = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/delete-postcode`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: DELETE_POST_CODE,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: DELETE_POST_CODE_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}


export const AddPostCode = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/add-postcode`, {
        postcodes: data
    }).then(async response => {
        let data = response.data.data;
        dispatch({
            type: ADD_POST_CODE,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: ADD_POST_CODE_ERR,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}

export const MasterListSearch = (data) => async (dispatch) => { 
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/masterlist`, {
        searchTerm: data
    }).then(async response => {
        let data = response.data.data;
        dispatch({
            type: SEARCH_MASTER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: LOADING,
            payload: false
        })
    })

}

