import React, { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { Card, CardBody, Col, Container, Media, Row, Button, Form } from "reactstrap";
import { object, string, mixed } from "yup"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Polygons from './map/Polygon';
import Maps from './map/Map';
import { useDispatch, useSelector } from 'react-redux';
import customAxios from '../../../customAxios';
import { BASE_URL } from '../../../config/constant';
import {  toast } from "react-toastify";
import { GETPROFILE } from '../../../redux/actions/SettingActions';
function DeliveryAddress() {

    const { profilecreated, profilelist, profileupdated } = useSelector(state => state.settings);

    const dispatch = useDispatch()
    const schema = object().shape({
    }).required();


    const [item, setItem] = useState(null)
    const [path, setPaths] = useState(null)
    // console.log({path})

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({

        resolver: yupResolver(schema),


    });

    const setDeliveryLocation = (value) => {
        setValue("coordinates", value)

    }

    useEffect(() => {
        if (profilelist) {

            let paths = profilelist?.deliverylocation?.coordinates?.map((loc) => {
                return {
                    lat: parseFloat(loc[0]),
                    lng: parseFloat(loc[1])
                }
            })
            setPaths(paths)

        }
    }, [profilelist])

    const onsubmit =
        async (data) => {

            try {
               
                await customAxios.post('admin/add-deliverylocations', { _id: profilelist?._id, ...data })
                dispatch(GETPROFILE()) 
                toast.success(` Updated Successfully`);
            } catch (err) {

            } finally {

            }


        }

    return (
        <Fragment>
            <Breadcrumb title="Delivery" parent="Settings" />
            <Container fluid={true}>
                <Col xl="12">
                    <Card className="profile-card">
                        <CardBody>
                            {profilelist?.deliverylocation?.coordinates && profilelist?.deliverylocation?.coordinates ? <Polygons onComplete={setDeliveryLocation} path={path} /> : <Maps onPolygonComplete={setDeliveryLocation} />}
                            {(errors && errors?.coordinates) && <span style={{ color: 'red', fontSize: 12 }}>{`${errors?.coordinates?.message}`}</span>}
                        </CardBody>
                    </Card>
                </Col>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(onsubmit)}
                >
                    Save
                </Button>
            </Container>
        </Fragment>
    )
}

export default DeliveryAddress