import React, { useState, useEffect } from 'react'
import { Modal, Button, Col, Form, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../common/CustomInput';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import SelectInput from '../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../redux/constants/userConstants';
import { CreateStore, UpdateStore, UpdateStoreManagement } from '../../redux/actions/storeActions';
import Reactotron from "reactotron-react-js"
import MyMapComponent from './MyMapComponent';
import EditMap from './EditMap';
import customAxios from '../../customAxios';

const AddStore = ({ openModal, onCloseModal, data, setActiveData, title }) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { error } = useSelector(state => state.store)
    const [inputFocused, setInputFocused] = useState(false);
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

    const [postCodes, setPostCodes] = useState([]);



    const schema = yup.object({
        storename: yup.string().required("Store is required")
            .matches(/^[A-Za-z\s]+$/, "Store name must contain only alphabets")
            .max(40, "Store name must be at most 40 characters")
        ,
        storeemail: yup.string().email("Email is not valid").required('Email is required'),
        primary_contact: yup
            .string()
            .nullable()
            .notRequired() // Allows the field to be null or undefined
            .test("isMobile", "Contact number needs to be atleast 10 digits", (value) => {
                if (!value) return true; // Skips validation if the value is null, undefined, or an empty string
                const pmobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
                return pmobileRegex.test(value);
            })
            .test("lengthCheck", "Contact number needs to be exactly 10 digits", (value) => {
                if (!value) return true; // Skip length check if value is empty
                return value.length === 10; // Only check length if there is a value
            }),
        sales_mobile: yup
            .string()
            .nullable()
            .notRequired() // Allows the field to be null or undefined
            .test("isMobile", "Sales number needs to be atleast 10 digits", (value) => {
                if (!value) return true; // Skips validation if the value is null, undefined, or an empty string
                const smobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
                return smobileRegex.test(value);
            })
            .test("lengthCheck", "Sales number needs to be exactly 10 digits", (value) => {
                if (!value) return true; // Skip length check if value is empty
                return value.length === 10; // Only check length if there is a value
            }),

        address: yup.object().required('Address is required').typeError('Address is required'),
        delivery_location: yup.array().required('Delivery location is required').typeError('Delivery location is required'),
    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, getValues, setError, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            storename: '',
            storeemail: '',
            primary_contact: '',
            sales_mobile: '',
            address: '',
            delivery_location: ''
        }
    });


    useEffect(() => {
        if (data) {
            reset({
                storename: data?.storename,
                storeemail: data?.storeemail,
                primary_contact: data?.primary_contact,
                sales_mobile: data?.sales_mobile,
                address: data?.address,
                delivery_location: data?.delivery_location
            })
        } else {
            reset({
                storename: '',
                storeemail: '',
                primary_contact: '',
                sales_mobile: '',
                address: '',
                delivery_location: ''
            })
        }
    }, [data])

    const getCodes = async () => {
        try {
            setLoading(true);
            let res = await customAxios.get(`admin/postcodelist`);
            const items = res?.data?.data?.map(({ name, _id }) => ({
                label: name,
                value: _id,
            }));

            setPostCodes(items);
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCodes();
    }, []);

    const createstore = (datas) => {

        const formData = {
            "storename": datas?.storename,
            "storeemail": datas?.storeemail,
            "address": datas?.address,
            "primary_contact": datas?.primary_contact,
            "sales_mobile": datas?.sales_mobile,
            "delivery_location": datas?.delivery_location?.map((nam) => ({
                label: nam?.label,
                value: nam?.value
              }))
        }

        Reactotron.log(formData, "fd")

        dispatch(data ? UpdateStoreManagement({ store_id: data?._id, ...formData }) : CreateStore(formData))
    }

    useEffect(() => {
        if (error) {
            // Reactotron.log(error,"err")
            // toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])

    const clearclose = () => {
        onCloseModal()
        reset()

    }

    return (
        <>
            <style>
                {`
                .pac-container {
                    z-index: 1050 !important;
                    margin-top: -80px;
                }
            `}
            </style>
            <Modal isOpen={openModal} toggle={clearclose} size="lg">
                <ModalHeader toggle={clearclose}>
                    <h5
                        className="modal-title f-w-600"
                        id="exampleModalLabel2"
                    >
                        {title}
                    </h5>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col>
                                <CustomInput
                                    fieldLabel='Store Name'
                                    control={control}
                                    error={errors.storename}
                                    fieldName='storename'
                                // readOnly={data?.storename}
                                />
                            </Col>
                            <Col>
                                <CustomInput
                                    fieldLabel='Email Address'
                                    control={control}
                                    error={errors.storeemail}
                                    // readOnly={data?.storeemail}
                                    fieldName='storeemail'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomInput
                                    fieldLabel='Contact Number'
                                    control={control}
                                    error={errors.primary_contact}
                                    fieldName='primary_contact'
                                    //readOnly={data?.primary_contact}
                                    type={'number'}
                                    code
                                />
                            </Col>
                            <Col>
                                <CustomInput
                                    fieldLabel='Contact Number (Sales)'
                                    control={control}
                                    error={errors.sales_mobile}
                                    // readOnly={data?.sales_mobile}
                                    fieldName='sales_mobile'
                                    type={'number'}
                                    code
                                />
                            </Col>
                        </Row>
                        <Row xl="1">
                            <Col>
                                <div>{data?._id ? <EditMap setValue={setValue} control={control} editable={data?._id} /> :
                                    <MyMapComponent setValue={setValue} control={control} editable={data?._id} />}
                                </div>
                            </Col>
                        </Row>
                        <Row xl="1">
                            <Col>
                                <SelectInput
                                    fieldLabel="Delivery Locations"
                                    control={control}
                                    error={errors.delivery_location}
                                    fieldName="delivery_location"
                                    options={postCodes}
                                    isMultiple
                                // onChangeValue={onChangeValue}
                                />
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        disabled={loading ? true : false}
                        color="primary"
                        onClick={handleSubmit(createstore)}
                    >
                        {loading ? "Saving" : "Save"}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddStore
