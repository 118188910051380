
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AddSubCategory from "./AddSubCategory";

import { useDispatch, useSelector } from "react-redux";
import { DeleteCategory, DelsubCategory, GetCategory, GetsubCategory } from "../../../redux/actions/productActions";
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../../common/header_components/searchHeader";
import { toast } from 'react-toastify'


const ProductSubCategory = () => {
    const dispatch = useDispatch()
    const { newsubcategory, getsubcategoryList, subcategorydelsuccess, subcategoryedited, error } = useSelector(state => state.product)
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)
    const [datas, setDatas] = useState([])
    const [title, setTitle] = useState('')
    const [filterText, setFilterText] = useState('');

    const customToast = "delSub";

    // console.log(getsubcategoryList, "getsubcategoryList")
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DelsubCategory(id))
        }
    }

    const handleEdit = (data) => {
        setTitle('Edit Sub Category')
        setActiveData(data)
        setOpen(true)

    }

    const onOpenModal = () => {
        setTitle("Add Sub Category")
        setActiveData(null)
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    const onOpenEditModal = () => {
        setOpenEdit(true);
    };

    const onCloseEditModal = () => {
        setOpenEdit(false);
    };

    useEffect(() => {
        if (newsubcategory) {
            toast.success("Sub Category created successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal()
    }, [newsubcategory])

    useEffect(() => {
        if (subcategorydelsuccess) {
            toast.success("Sub Category Deleted successfully", { toastId: customToast });
            dispatch({
                type: RESET_ERROR
            })

        }

    }, [subcategorydelsuccess])

    useEffect(() => {
        if (subcategoryedited) {
            toast.success("sub category updated Successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal()
    }, [subcategoryedited])

    useEffect(() => {
        if (error) {
            toast.warning(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])


    useEffect(() => {
        dispatch(GetsubCategory())
    }, [])

    useEffect(() => {
        if (getsubcategoryList) {
            setDatas(getsubcategoryList)
        }
    }, [getsubcategoryList])




    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Category Name")}</b>,
            header: <b>{Capitalize("Category Name")}</b>,
            selector: row => row?.category?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Sub Category Name")}</b>,
            header: <b>{Capitalize("Sub Category Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Description")}</b>,
            header: <b>{Capitalize("description")}</b>,
            selector: row => row?.description,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("GST (%)")}</b>,
            header: <b>{Capitalize("GST (%)")}</b>,
            selector: row => (row?.tax === 'null' || !row?.tax) ? '-' : row?.tax,
            style: {
                textAlign: "center",
            },
        },


        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)}>

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    useEffect(() => {
        // if(filterText){
        let datas = getsubcategoryList?.filter(sunC => sunC?.name?.toLowerCase().includes(filterText?.toLowerCase()) || sunC?.category?.name?.toLowerCase().includes(filterText?.toLowerCase()))
        setDatas(datas)
        // }
    }, [filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText]);


    return (
        <Fragment>
            <Breadcrumb title="Sub Category" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Sub Category</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"

                                    >
                                        Add Sub Category

                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {getsubcategoryList && <DataTable
                                        pagination
                                        columns={columns}
                                        data={datas}
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddSubCategory title={title} openModal={open} onCloseModal={() => {
                setActiveData(null)
                setOpen(false)
            }} data={activeData} setActiveData={setActiveData} />


            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}

export default ProductSubCategory