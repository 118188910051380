import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
    Col,
    Form,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Toast,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";

const ChangeStock = ({ openModal, onCloseModal, fetchProducts, stockData }) => {

    console.log(stockData, "datStock")
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState({
        value: "percentage",
        label: "Percentage",
    });

    console.log(type, "type")

    const schema = yup.object({
        stock: yup
            .number()
            .typeError("Stock value must be a number")
            .required("Stock value is required")

        // role: yup.object().nullable()
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            stock: "",
        },
    });

    const clearclose = () => {
        onCloseModal();
        reset();
    };


    const createStock = async (data) => {
        console.log(data, "formData");

        try {
            setLoading(true);

            const payload = {
                category_id: stockData.category.id,
                units: stockData.units.map(unit => ({
                    id: unit.id,
                    name: unit.name,
                    variants: unit.variants.map(variant => ({
                        name: variant.name,
                        description: variant.description,
                        costPrice: variant.costPrice,
                        sellingPrice: variant.sellingPrice,
                        stock: data.stock, // Use the stock from the form data
                        weight: variant.weight,
                        height: variant.height,
                        length: variant.length,
                        offerPrice: variant.offerPrice,
                        fromDate: variant.fromDate,
                        toDate: variant.toDate
                    }))
                })),
                id: stockData._id
            };

            const response = await customAxios.post(`admin/add-stock`, payload);

            toast.success(response?.data?.message);
            reset({
                stock: "", // reset stock field after submission
            });

            // Call fetchProducts to refresh the product list
            fetchProducts();

            onCloseModal();
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal isOpen={openModal} toggle={clearclose} size="md">
            <ModalHeader toggle={clearclose}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Add Stock
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>

                    <Col>
                        <CustomInput
                            fieldLabel="Stock"
                            control={control}
                            error={errors.value}
                            fieldName="stock"
                            type={'number'}
                        />
                    </Col>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading}
                    type="button"
                    color="primary"
                    onClick={handleSubmit(createStock)}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                        onCloseModal();
                        reset();
                    }}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ChangeStock;
