import React, { Fragment, useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../common/breadcrumb";
import MyDropzone from "../../common/dropzone";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Form,
  Input,
  Row,
} from "reactstrap";
import CustomInput from "../../common/CustomInput";
import SelectInput from "../../common/SelectInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableData from "./TableData";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  ADDPRODUCTS,
  editproductd,
  editProductPrice,
  GetBrand,
  GetCategory,
  GETFEATURED,
  getSingleProductlist,
  GetsubCategory,
  GetsubCategorybyid,
  getuniltList,
} from "../../../redux/actions/productActions";
import ImageUploading from "react-images-uploading";
import moment from "moment";
import { toast } from "react-toastify";
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import AddUnitmodal from "./AddUnitmodal";
import { classify } from "i/lib/methods";
import "../../../assets/scss/addproduct.scss";
import { useParams, useNavigate } from "react-router-dom";
import { Plus, Edit, Trash } from "react-feather";
// import BigInput from "../../common/BigInput";
import CustomTextArea from "../../common/CustomTextArea";
import Reactotron from "reactotron-react-js";
import TopicModal from "./TopicModal";
import customAxios from "../../../customAxios";

const AddProduct = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    getcatogrylist,
    getbrandlist,
    subcategorylistbyid,
    getsubcategoryList,
    featuredList,
    unitList,
    productcreated,
    error,
    singleproductList,
    productupdatedD,
    productTopics,
  } = useSelector((state) => state.product);

  const [variantsData, setVariantsData] = useState([]);
  const [imageArray, setImageArray] = useState(null);
  const [featured, setfeatured] = useState([]);
  const [Images, setImages] = useState([]);
  const [unitlistStore, setUnitlist] = useState([]);
  const [value, setvalue] = useState(false);
  const [categoryArray, setCategoryArray] = useState(null);
  const [subcategoryArray, setSubCategoryArray] = useState([]);
  const [featuredArray, setFeaturedArray] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pros, setPros] = useState([]);
  // const [brandArray, setBrandArray] = useState(null);
  // const [topic, setTopic] = useState(false);
  const [editItem, setEditItem] = useState(null);
  // const [topicData, setTopicData] = useState([]);

  console.log(subcategoryArray, "sub")
  console.log(pros, "pros")
  Reactotron.log(variantsData, "variantsData")
  Reactotron.log(singleproductList, "singleproductList")

  const maxNumber = 100;
  const acceptType = ["jpg", "png", "jpeg"];
  const resolutionWidth = 512;
  const resolutionHeight = 512;
  const maxFileSize = 2000000;


  useEffect(() => {
    setImageArray(null);
  }, []);

  // const onCloseModal = useCallback(() => {
  //   if (topic) {
  //     setEditItem(null);
  //   }

  //   setTopic(!topic);
  // }, [topic]);

  // useEffect(() => {
  //   if (editItem) {
  //     onCloseModal();
  //   }
  // }, [editItem]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProductlist(id));
    } else {
      reset();
      setVariantsData([]);
      setImageArray(null);
      setfeatured([]);
      setImages([]);
      setPros([]);
      // setTopicData([]);
      let features = [];
      featuredList?.map((feature) => {
        features.push({
          ...feature,
          checked: false,
        });
      });
      setFeaturedArray(features);
    }
  }, [id]);

  const schema = yup
    .object({
      name: yup.string().required("Name field is required"),
      category: yup.object().when("edit", {
        is: false,
        then: yup.object().required("Category Required"),
      }),
      related: yup
        .array()
        .nullable()
      // .required("Related Products is required")
      // .typeError("Please select a product"),
      // countryoforigin: yup
      //   .string()
      //   .nullable()
      //   .matches(/^[A-Za-z]*$/, "Only alphabets are allowed"),
      // brand: yup.object().nullable(),
      // subcategory: yup.object().when("sub", {
      //     is: true,
      //     then: yup.object().required("Sub Category Required").typeError("Sub category required"),
      // })
    })
    .required();
  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sub: true,
      name: "",
      description: "",
      // details: "",
    },
  });

  // console.log(getValues(), 'check values');

  useEffect(() => {
    let features = [];
    if (featuredList) {
      if (singleproductList?.featuredList) {
        featuredList?.map((feature) => {
          let findfeature = singleproductList?.featuredList?.find(
            (prod) => prod?._id === feature?._id
          );
          if (findfeature) {
            features.push({
              ...feature,
              checked: true,
            });
          } else {
            features.push({
              ...feature,
              checked: false,
            });
          }
        });
      } else {
        features = featuredList;
      }
    }

    setFeaturedArray(features);
  }, [featuredList, singleproductList]);

  const watchcategory = watch("category");

  const getProducts = async () => {
    try {
      setLoading(true);
      let res = await customAxios.get(`admin/productlist`);

      console.log(res, "resss")

      const items = res?.data?.data?.map(({ name, _id }) => ({
        label: name,
        value: _id,
      }));

      setPros(items);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    // console.log(singleproductList, "list")
    if (id && singleproductList) {
      // console.log({
      //     value: singleproductList?.brand?.id,
      //     label: singleproductList?.brand?.name,
      // });
      setFeaturedArray(null);
      setValue("name", singleproductList?.name);
      setValue("countryoforigin", null);
      setValue("brand", null);
      setValue("related", singleproductList?.relatedProducts ? singleproductList?.relatedProducts : null);
      // setValue(
      //   "countryoforigin",
      //   singleproductList?.countryoforigin === null
      //     ? ""
      //     : singleproductList?.countryoforigin
      // );
      // setValue("brand", {
      //   value: singleproductList?.brand?.id,
      //   label: singleproductList?.brand?.name,
      //   slug: singleproductList?.brand?.slug,
      // });
      setValue("category", {
        value: singleproductList?.categories?._id,
        label: singleproductList?.categories?.name,
      });
      setValue("subcategory", {
        value: singleproductList?.subcategories?._id,
        label: singleproductList?.subcategories?.name,
      });
      setValue(
        "description",
        singleproductList?.description === null
          ? ""
          : singleproductList?.description
      );
      setValue("details", null);
      // setValue(
      //   "details",
      //   singleproductList?.details === null ? "" : singleproductList?.details
      // );

      let images = singleproductList?.image?.map((im) => {
        return `${singleproductList?.imageBasePath}${im}`;
      });

      setImageArray(images);

      let units = singleproductList?.categories?.unit.map((un) => {
        return {
          value: un.id,
          label: un.name,
        };
      });

      //console.log({units})
      setUnitlist(units);

      let variants = singleproductList?.variants?.map((vari) => {
        return {
          uid: vari._id,
          id: {
            value: vari?.units?._id,
            label: vari?.units?.name,
          },
          name: vari.name,
          description: vari.description,
          selling: vari.sellingPrice,
          regular: vari.costPrice,
          stock: !String(vari.stock) || String(vari.stock) === "null" ? "" : String(vari.stock),
          weight: vari.weight ? vari.weight : "",
          height: vari.height ? vari.height : "",
          length: vari.length ? vari.length : "",
          offer: vari.offerPrice,
          fromDate: vari.fromDate ? moment(vari.fromDate) : null,
          toDate: vari.toDate ? moment(vari.toDate) : null,
        };
      });

      setVariantsData(variants);

      let features = [];
      if (singleproductList?.featuredList) {
        featuredList?.map((feature) => {
          let findfeature = singleproductList?.featuredList?.find(
            (prod) => prod?._id === feature?._id
          );
          if (findfeature) {
            features.push({
              ...feature,
              checked: true,
            });
          } else {
            features.push({
              ...feature,
              checked: false,
            });
          }
        });
        setFeaturedArray(features);
      }
    }

    if (!id) {
      setValue("name", "");
      setValue("category", null);
      setValue("subcategory", null);
      setValue("description", "");
      setValue("brand", null);
      setValue("countryoforigin", null);
      setValue("details", null);

      setImageArray(null);

      setUnitlist([]);

      setVariantsData([]);
      let features = [];
      featuredList?.map((feature) => {
        features.push({
          ...feature,
          checked: false,
        });
      });
      setFeaturedArray(features);
    }
  }, [id, singleproductList]);

  // useEffect(() => {
  //   if (id && productTopics) {
  //     const data = productTopics?.map(({ _id, name, blogs }) => ({
  //       topic: { label: name, value: _id },
  //       blog: blogs?.map(({ _id, title }) => ({
  //         label: title,
  //         value: _id,
  //       })),
  //     }));

  //     setTopicData(data);
  //   }
  // }, [id, productTopics]);

  useEffect(() => {
    dispatch(GetCategory());
    dispatch(GetsubCategory());
    // dispatch(GetBrand());
    dispatch(GETFEATURED());
    dispatch(getuniltList());

    return () => {
      setImageArray(null);
    };
  }, []);

  useEffect(() => {
    if (getcatogrylist) {
      setValue("categoryList", getcatogrylist);
      let categories = [{ value: "", label: "select" }];
      getcatogrylist.map((cat) => {
        categories.push({
          value: cat?._id,
          label: cat?.name,
        });
      });

      setCategoryArray(categories);
    }

    if (subcategorylistbyid) {
      console.log(subcategorylistbyid, "Fsd")
      let subs = subcategorylistbyid.map((su) => {
        return {
          value: su._id,
          label: su.name,
        };
      });

      if (subs.length === 0) {
        setValue("sub", false);
      }

      setSubCategoryArray(subs);
    }

    // if (getbrandlist) {
    //   setValue("brandList", getbrandlist);
    //   let brands = [{ value: "", label: "select", slug: "" }];
    //   getbrandlist.map((bran) => {
    //     brands.push({
    //       value: bran?._id,
    //       label: bran?.name,
    //       slug: bran?.slug,
    //     });
    //   });

    //   setBrandArray(brands);
    // }
  }, [getcatogrylist, subcategorylistbyid, getbrandlist]);

  useEffect(() => {
    if (getValues("category") && !id) {
      setValue("subcategory", "");
    }
  }, [getValues("category")]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "category" && value?.category?.value) {
        populateSubCategory(value?.category?.value, value.categoryList);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const populateSubCategory = (category, categoryList) => {
    console.log(category, "FSADf")
    let list = categoryList.find((res) => res._id === category);
    let units = list.unit.map((un) => {
      return {
        value: un.id,
        label: un.name,
      };
    });

    //console.log({units})
    setUnitlist(units);
    dispatch(GetsubCategorybyid(category));
  };

  const onChange = (imageList, addUpdateIndex) => {
    // console.log({ imageList, addUpdateIndex })
    // data for submit
    var reader = new FileReader();
    setImages(imageList);
  };

  // const onChangeBranch = (item) => {
  //   setValue("brand", item);
  // };

  const onChangeCategory = (item) => {
    setValue("category", item);
  };

  if (pros?.length > 0) {
    const data = pros?.map((nam) => ({
      label: nam?.label,
      value: nam?.value
    }));

    console.log(data, "ddd")
  }

  const handlechange = (e, checked) => {
    if (checked) {
      // featureItems.add(e);
      let find = featured.find((feat) => feat.id === e);

      if (!find) {
        setfeatured((prv) => [...prv, { id: e }]);
      }
    } else {
      let features = featured.filter((feat) => feat.id !== e);
      setfeatured(features);
    }
  };

  const submitform = (data) => {
    Reactotron.log(data, "Fdsfdfsd")
    setLoading(true);

    // if (topicData?.find(({ blog }) => blog?.length === 0)) {
    //     toast.error('Blog is required');
    //     setLoading(false);
    //     return;
    //   }
    const formData = new FormData();


    if (data?.related?.length > 0) {
      const proData = data?.related?.map((nam) => ({
        label: nam?.label,
        value: nam?.value
      }));
      formData.append("recomended_product_id", JSON.stringify(proData));
    } else {
      formData.append("recomended_product_id", null);
    }

    //console.log({variantsData, data})
    let units = [];
    variantsData.map((variant) => {
      let vari = {
        name: variant.name,
        description: variant.description,
        costPrice: variant.regular,
        sellingPrice: variant.selling,
        stock: variant.stock ? variant.stock : null,
        weight: variant.weight ? variant.weight : null,
        height: variant.height ? variant.height : null,
        length: variant.length ? variant.length : null,
        offerPrice: variant.offer,
        fromDate: variant.fromDate
          ? moment(variant.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: variant.toDate
          ? moment(variant.toDate).format("YYYY-MM-DD")
          : null,
      };
      let findUnit = units.find((un) => un.id === variant.id.value);
      if (findUnit) {
        findUnit.variants.push(vari);

        units = units.map((un) => (un.id === variant.id.value ? findUnit : un));
      } else {
        let newunit = {
          id: variant.id.value,
          name: variant.id.label,
          variants: [vari],
        };

        units.push(newunit);
      }
    });

    let features = [];
    featuredArray.map((fea) => {
      if (fea.checked) {
        features.push({
          id: fea._id,
        });
      }
    });

    //console.log({units})

    formData.append("description", data?.description);
    formData.append("details", data?.details ? data?.details : null);
    formData.append(
      "brand",
      data?.brand
        ? JSON.stringify({
          id: data?.brand?.value,
          name: data?.brand?.label,
          slug: data?.brand?.slug,
        })
        : null
    );
    formData.append("countryoforigin", data?.countryoforigin ? data?.countryoforigin : null);

    formData.append(
      "featured",
      features.length === 0 ? [] : JSON.stringify(features)
    );
    Images &&
      Images?.map((img, i) => {
        formData.append(`image[${i}]`, img.file);
      });

    if (!id) {
      formData.append("name", data?.name);
      formData.append(
        "category",
        JSON.stringify({
          id: data?.category?.value,
          name: data?.category?.label,
        })
      );
      formData.append(
        "subCategory",
        data?.subcategory.value
          ? JSON.stringify({
            id: data?.subcategory?.value,
            name: data?.subcategory?.label,
          })
          : null
      );
      formData.append("units", JSON.stringify(units));
      Reactotron.log(data, "form")
      dispatch(ADDPRODUCTS(formData)).finally(() => setLoading(false));
    } else {
      formData.append("id", id);
      formData.append("status", singleproductList?.status);
      dispatch(editproductd(formData)).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (productcreated) {
      toast.success("Product Created successfully");
      setValue("name", "");
      setValue("category", "");
      setValue("subcategory", "");
      setValue("brand", "");
      setValue("related", "");
      setValue("countryoforigin", "");
      setValue("description", "");
      setValue("details", "");
      setImages([]);
      setVariantsData([]);
      dispatch({
        type: RESET_ERROR,
      });
      navigate("/products/products");
    }
    if (productupdatedD) {
      toast.success("Product updated successfully");
      navigate("/products/products");
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [productcreated, productupdatedD]);

  useEffect(() => {
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [error]);

  const saveVariantsPrice = (variants) => {
    let units = [];
    variants.map((variant) => {
      let vari = {
        name: variant.name,
        description: variant.description,
        costPrice: variant.regular,
        sellingPrice: variant.selling,
        stock: variant.stock ? variant.stock : null,
        weight: variant.weight ? variant.weight : null,
        height: variant.height ? variant.height : null,
        length: variant.length ? variant.length : null,
        offerPrice: variant.offer,
        fromDate: variant.fromDate
          ? moment(variant.fromDate).format("YYYY-MM-DD")
          : null,
        toDate: variant.toDate
          ? moment(variant.toDate).format("YYYY-MM-DD")
          : null,
      };
      let findUnit = units.find((un) => un.id === variant.id.value);
      if (findUnit) {
        findUnit.variants.push(vari);

        units = units.map((un) => (un.id === variant.id.value ? findUnit : un));
      } else {
        let newunit = {
          id: variant.id.value,
          name: variant.id.label,
          variants: [vari],
        };

        units.push(newunit);
      }
    });

    let input = {
      category_id: singleproductList?.categories?._id,
      subcategory_id: singleproductList?.subcategories?._id,
      units: units,
      id: id,
    };

    dispatch(editProductPrice(input));
  };

  const addVariants = (value) => {
    let variants = variantsData.filter((vari) => vari.uid !== value.uid);

    // console.log(variants, "var")

    let newVariants = variants ? [...variants, value] : [value];
    // console.log(newVariants, "newVariants")

    if (id) {
      saveVariantsPrice(newVariants);
      toast.success("Product variant updated");
    } else {
      setVariantsData(newVariants);
    }
  };

  const deleteVariants = (variantId) => {
    Reactotron.log(variantId, "variantId")
    let variants = variantsData.filter((vari) => vari.uid !== variantId);
    setVariantsData(variants)
    if (id) {
      saveVariantsPrice(variants);
    }
  };

  const handleFeaturechange = (id, value) => {
    setFeaturedArray((current) =>
      current.map((fe) => {
        if (fe._id === id) {
          return {
            ...fe,
            checked: value,
          };
        } else {
          return fe;
        }
      })
    );
  };

  return (
    <Fragment>
      <Breadcrumb
        title={id ? "Edit Product" : "Add Product"}
        parent="Digital"
      />
      <Container fluid={true}>
        <Row className="product-adding">
          <Col xl="12">
            <Card>
              <CardBody>
                <div className="digital-add needs-validation">
                  <Row xl="3">
                    <CustomInput
                      fieldLabel="Product Name :"
                      control={control}
                      error={errors.name}
                      fieldName="name"
                      disabled={!id ? false : true}
                    />

                    <SelectInput
                      fieldLabel="Category Name :"
                      control={control}
                      error={errors.category}
                      fieldName="category"
                      onChangeValue={onChangeCategory}
                      options={categoryArray}
                    />
                    <SelectInput
                      fieldLabel="Sub Category:"
                      control={control}
                      error={errors.subcategory}
                      fieldName="subcategory"
                      // onChangeValue={(value) => getSubCateoryid(value)}
                      options={subcategoryArray}
                    />
                  </Row>
                  <Row xl="2">
                    <Col xl="6">
                      {/* <SelectInput
                        fieldLabel="Brand :"
                        control={control}
                        error={errors.brand}
                        fieldName="brand"
                        onChangeValue={onChangeBranch}
                        options={brandArray}
                      />
                      <CustomTextArea
                        fieldLabel="Product Details :"
                        control={control}
                        error={errors.details}
                        fieldName="details"
                        type="textarea"
                      /> */}
                      <CustomTextArea
                        fieldLabel="Product Description :"
                        control={control}
                        error={errors.description}
                        fieldName="description"
                        type="textarea"
                      />
                      <SelectInput
                        fieldLabel="Related Products"
                        control={control}
                        error={errors.related}
                        fieldName="related"
                        options={pros}
                        isMultiple
                      // onChangeValue={onChangeValue}
                      />
                    </Col>
                    {/* <ImageInput
                                            fieldLabel='Product Image :'
                                            control={control}
                                            error={errors.prod_image}
                                            fieldName='prod_image'
                                            minHeight="100"
                                        /> */}
                    <Col xl={6}>
                      {/* <CustomInput
                        fieldLabel="Country Of Origin :"
                        control={control}
                        error={errors.countryoforigin}
                        fieldName="countryoforigin"
                      /> */}
                      <div
                        style={{
                          marginBottom: "30px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {imageArray?.map((res, i) => (
                          <img
                            src={`${res}`}
                            alt="images"
                            style={{
                              width: "80px",
                              height: "80px",
                            }}
                          ></img>
                        ))}
                      </div>

                      <div
                        style={{
                          border: "2px dotted",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <ImageUploading
                          multiple
                          value={Images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          acceptType={acceptType}
                          dataURLKey="data_url"
                          resolutionType="ratio"
                          resolutionWidth={resolutionWidth}
                          resolutionHeight={resolutionHeight}
                          maxFileSize={maxFileSize}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                            errors,
                          }) => (
                            // write your building UI

                            <div className="upload__image-wrapper">
                              <div>
                                {errors?.maxNumber && (
                                  <span style={{ color: "red" }}>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors?.maxFileSize && (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors?.resolution && (
                                  <span style={{ color: "red" }}>
                                    Image resolution should be greater than 512
                                    * 512 and should be equal width and height{" "}
                                  </span>
                                )}
                              </div>
                              <div
                                style={{
                                  paddingTop: "10px",
                                  paddingbottom: "50px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    background: "#13c9ca",
                                    borderRadius: "10px",
                                    width: "90%",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                  }}
                                  // style={isDragging ? { color: 'red' } : undefined}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Upload Image
                                </button>
                              </div>
                              &nbsp;
                              {/* <button onClick={onImageRemoveAll} >Remove all images</button> */}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {imageList.map((image, index) => (
                                  <div
                                    key={index}
                                    className="image-item"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={image["data_url"]}
                                      alt=""
                                      width="100"
                                      height="100"
                                    />
                                    <div
                                      className="image-item__btn-wrapper"
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <button
                                        onClick={() => onImageUpdate(index)}
                                        style={{
                                          border: "none",
                                          cursor: "pointer",
                                          background: "rgb(19 201 202 / 49%)",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Update
                                      </button>
                                      <button
                                        onClick={() => onImageRemove(index)}
                                        style={{
                                          border: "none",
                                          cursor: "pointer",
                                          background: "#ff8084",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </ImageUploading>
                        <span>Recommended Image resolution 512*512</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Col xl="12">
          <Card>
            <CardBody>
              <div>
                <h1 style={{ fontSize: "12px" }}>Feature List</h1>
              </div>
              <Form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  {featuredArray?.map((x) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          paddingTop: "10px",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={x._id}
                          checked={x.checked}
                          onChange={(e) =>
                            handleFeaturechange(x._id, e.target.checked)
                          }
                        />
                        <label>{x.name}</label>
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "left",
                    // marginTop: 50,
                  }}
                >
                  {/* <Button color="primary" onClick={onCloseModal}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center',
                    gap: 2,
                  }}
                >
                  <Plus size={23} />
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 14,
                    //   marginTop: 20,
                    }}
                  >
                    Add Topic
                  </p>
                </div>
              </Button> */}
                </div>

                {/* {topicData?.map((data, index) => (
              <div className="topic__item">
                <textarea
                  type="textarea"
                  value={data?.topic?.label}
                  readOnly
                  style={{
                    resize: "none",
                    padding: 5,
                    overflow: "hidden",
                    outline: "none",
                    flex: 0.4,
                    width: "100%",
                    height: "34px",
                  }}
                />
                <Select
                  styles={{
                    container: (css) => ({
                      ...css,
                      flex: 0.4,
                    }),
                    control: (css) => ({
                      ...css,
                      width: "100%",
                      // background: '#fff'
                    }),
                  }}
                  isDisabled
                  value={data?.blog}
                  isMulti
                />

                <div className="topic__buttons">
                  <Button
                    onClick={() => {
                      setEditItem(data);
                    }}
                  >
                    <Edit color="#555" />
                  </Button>

                  <Button
                    onClick={() =>
                      setTopicData((topicData) =>
                        topicData?.filter(
                          ({ topic }) => topic?.value !== data?.topic?.value
                        )
                      )
                    }
                  >
                    <Trash color="crimson" />
                  </Button>
                </div>
              </div>
            ))} */}
              </Form>
            </CardBody>
          </Card>

          {/* <TopicModal
            openModal={topic}
            setTopicData={setTopicData}
            onCloseModal={onCloseModal}
            topicData={topicData}
            editItem={editItem}
          /> */}
        </Col>
        <Col xl="12">
          <Card>
            <CardBody>
              {unitlistStore && (
                <TableData
                  onDelete={(id) => deleteVariants(id)}
                  variant={variantsData}
                  onAdd={(value) => addVariants(value)}
                  setVariantsData={setVariantsData}
                  unitliststore={unitlistStore}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <Button
                  onClick={handleSubmit(submitform)}
                  disabled={
                    variantsData?.length === 0 || loading ? true : false
                  }
                >
                  {loading ? "Saving" : "Submit"}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default AddProduct;
