import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import data from '../unit/unitdata';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import SelectInput from '../../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { CreateCategory, getuniltList, UpdateCategory } from '../../../redux/actions/productActions';
import ImagesInput from '../../common/ImagesInput';
import EditCategory from './EditCategory';
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../../redux/constants/userConstants';

const AddCategory = ({ openModal, onCloseModal, data, setActiveData, title }) => {



    const dispatch = useDispatch()

    const [datastore, setdatastore] = useState(data)
    const [unitArray, setUnitArray] = useState(null)
    const [images, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const { unitList, newcategory } = useSelector(state => state.product)
    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
    const schema = yup.object({
        image: yup.mixed().nullable(),
        name: yup.string()
        .matches(/^[A-Za-z\s]+$/, "Category name must contain only alphabets")
        .max(40, "Category name must be at most 40 characters")
        .required("Category is required"),
        tax: yup.string()
        .nullable(),
        unit: yup.array().min(1, "At least 1 unit required").required("Unit required"),


    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            image: null,
            unit: [],
            localImage: false,
            name: '',
            tax: '',
            description: ''
        }
    });

    //console.log({errors})


    useEffect(() => {
        if (unitList) {
            let newUnits = unitList.map(unit => {
                return {
                    value: unit._id,
                    label: unit.name
                }
            })

            setUnitArray(newUnits);
        }

        if (newcategory) {
            reset()
        }


    }, [unitList, newcategory])


    const createcategory = (datas) => {

        setLoading(true)

        // console.log({datas})
        // let units = unitList.find((data) => data.id === datas._id)

        // let unit = [{
        //     id: units._id,
        //     name: units.name
        // }]

        let units = datas.unit.map(un => {
            return {
                id: un.value,
                name: un.label
            }
        })


        if (data) {

            // let input = {
            //     name: datas?.name === "" ? data?.name : data?.name,
            //     id: data?._id,
            //     status: data?.status,
            //     description: datas?.discription === "" ? data?.discription : data?.discription,
            //     tax: datas?.tax === "" ? data?.tax : data?.tax,
            //     image:datas?.image
            // }
            const formData = new FormData();
            formData.append('name', datas.name);
            formData.append('id', data?._id);
            formData.append('status', data?.status);
            formData.append('description', datas.description);
            formData.append('unit', JSON.stringify(units));
            formData.append('tax', datas?.tax || null);
            if (datas?.image) {
                formData.append('image', datas.image);
            }
            dispatch(UpdateCategory(formData)).finally(() => setLoading(false));
            // setValue("name", "")
            // setValue("description", "")
            // setValue("tax", "")
            // setActiveData(null)
        }
        else {
            const formData = new FormData();
            formData.append('name', datas.name);
            formData.append('description', datas.description);
            formData.append('tax', datas?.tax || null);
            formData.append('image', datas.image);
            formData.append('unit', JSON.stringify(units));
            dispatch(CreateCategory(formData)).finally(() => setLoading(false));
        }
    }


    useEffect(() => {
        if (data) {

            let units = data?.unit.map(un => {
                return {
                    value: un.id,
                    label: un.name
                }
            })

            // console.log({ units })
            setValue("name", data?.name)
            setValue("description", data?.description)
            // setValue("tax", (data?.tax === 'null' || data?.tax) ? '' : data?.tax ? data?.tax : null)
            setValue("tax", data?.tax ? data?.tax : null)
            setValue("unit", units ? units : [])
            setValue("localImage", data?.image ? true : false)
        }
    }, [data])


    const clearclose = () => {
        onCloseModal()
        reset()

    }

    useEffect(() => {
        dispatch(getuniltList())
    }, [])

    const onImgLoad = ({ target: img }) => {
        if (img.offsetWidth === 256 && img.offsetHeight === 256) {
            setValue("image", images)
        }
        else {
            setImage(null)
            setValue("image", null)
            setError("image", {
                type: 'custom',
                message: "Image resolution should be 256px * 256px"
            })
        }
    }

    return (

        <Modal isOpen={openModal} toggle={clearclose} size="lg">
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col>
                            <CustomInput
                                fieldLabel='Category Name :'
                                control={control}
                                error={errors.name}
                                fieldName='name'
                            />
                        </Col>
                        <Col>
                            <CustomInput
                                fieldLabel='Description :'
                                control={control}
                                error={errors.description}
                                fieldName='description'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomInput
                        fieldLabel='GST(%):'
                        control={control}
                        error={errors.tax}
                        type={'number'}
                        fieldName='tax'
                    />
                        </Col>
                        <Col>
                            <SelectInput
                                fieldLabel='Unit List'
                                control={control}
                                error={errors.unit}
                                fieldName='unit'
                                isMultiple={true}
                                options={unitArray}
                            //onChangeValue={(value) => console.log(value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {data === null ? "" : <img src={data?.image} style={{ width: 50, height: 50 }}></img>}

                            <ImagesInput
                                fieldLabel='Image : 256px * 256px'
                                control={control}
                                error={errors.image}
                                fieldName='image'
                                type='file'
                                onChangeValue={(file) => {
                                    setImage(file)
                                }}
                            />
                        </Col>
                        <Col>
                        </Col>
                    </Row>


                    {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}


                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    disabled={loading ? true : false}
                    color="primary"
                    onClick={handleSubmit(createcategory)}
                >
                    {loading ? "Saving" : "Save"}
                </Button>
                {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() =>{ 
                        onCloseModal()
                        reset()
                    }}
                >
                    Close
                </Button> */}
            </ModalFooter>
            {images && <div style={{ height: 1, opacity: 0 }} ><img onLoad={onImgLoad} src={URL.createObjectURL(images)} /></div>}
        </Modal>

    )
}

export default AddCategory
