import React, { useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";
import { Button, Col, Input, Label, Row, Table } from "reactstrap";
import ProfileModal from "./ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { GETPROFILE } from "../../../redux/actions/SettingActions";
import { toast } from 'react-toastify';
import { RESET_ERROR } from '../../../redux/constants/userConstants';
const TabsetProfile = () => {
	const { profilecreated, profilelist, error, profileupdated } = useSelector(state => state.settings);


	const dispatch = useDispatch()


	const [open, setOpen] = useState(false);
	const [edit, setedit] = useState(false)

	const onOpenModal = () => {

		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};



	useEffect(() => {
		dispatch(GETPROFILE())
	}, [open])


	useEffect(() => {
		if (profileupdated) {
			toast.success("Profile updated successfully");
			dispatch({
				type: RESET_ERROR
			})
			onCloseModal()
		}
	}, [profileupdated])
	useEffect(() => {
		if (error) {
			toast.warning(`${error.message}`);
			dispatch({
				type: RESET_ERROR
			})
		}
	}, [error])
	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}><Button onClick={() => (
				onOpenModal(),
				setedit(true)
			)}>EDIT PROFILE</Button></div>


			<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
				<div>
					<img src={`${profilelist?.image}${profilelist?.logo}`} alt="image" style={{ width: "100px", height: "100px" }} ></img>
				</div>
				{/* <h1 style={{fontSize:"18px"}}>profile</h1> */}
			</div>





			<div className="tab-pane fade show active">
				<h5 className="f-w-600 f-16">Profile</h5>
				<div className="table-responsive profile-table">
					<Table className="table-responsive">
						<tbody>
							<tr>
								<td>Store Name :</td>
								<td>{profilelist?.storename}</td>
							</tr>
							<tr>
								<td>Store Email :</td>
								<td>{profilelist?.storeemail}</td>
							</tr>
							<tr>
								<td>Address :</td>
								<td>{profilelist?.address}</td>
							</tr>
							<tr>
								<td>Mobile :</td>
								<td>+44 {profilelist?.mobile}</td>
							</tr>
							<tr>
								<td>GST (%) :</td>
								<td>{(profilelist?.gst === 'null' || !profilelist?.gst) ? '-' : profilelist?.gst}</td>
							</tr>
							<tr>
								<td>Delivery Charge :</td>
								<td>{profilelist?.delivery_charge}</td>
							</tr>
							{/* <tr>
								<td>Delivery Slot :</td>
								<td>{profilelist?.delivery_slot === 1 ? 'Enabled' : 'Disabled'}</td>
							</tr>
							<tr>
								<td>Primary Color :</td>
								<td>{profilelist?.primary_color === "undefined" ? "-" : profilelist?.primary_color}</td>
							</tr>
							<tr>
								<td>Secondary Color :</td>
								<td>{profilelist?.secondary_color === "undefined" ? "-" : profilelist?.secondary_color}</td>
							</tr>
							<tr>
								<td>Tertiary Color :</td>
								<td>{profilelist?.tertiary_color === "undefined" ? "-" : profilelist?.tertiary_color}</td>
							</tr>
							<tr>
								<td>DOB:</td>
								<td>Dec, 15 1993</td>
							</tr>
							<tr>
								<td>Location:</td>
								<td>USA</td>
							</tr>  */}
						</tbody>
					</Table>
				</div>
				<hr style={{ marginTop: -8 }} />
				<h5 className="f-w-600 f-16">Social Media</h5>
				<div className="table-responsive profile-table">
					<Table className="table-responsive">
						<tbody>
							<tr>
								<td>Facebook :</td>
								<td>{profilelist?.facebook_link ? profilelist?.facebook_link : "-"}</td>
							</tr>
							<tr>
								<td>Whatsapp :</td>
								<td>{profilelist?.whatsapp_link ? profilelist?.whatsapp_link : "-"}</td>
							</tr>
							{/* <tr>
								<td>Youtube :</td>
								<td>{profilelist?.google_link ? profilelist?.google_link : "-"}</td>
							</tr> */}
							<tr>
								<td>Instagram :</td>
								<td>{profilelist?.linkedin_link ? profilelist?.linkedin_link : "-"}</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<ProfileModal openModal={open} onCloseModal={() => {
				setOpen(false)
			}} datalist={profilelist} error={error} />

		</div>
	);
};

export default TabsetProfile;
