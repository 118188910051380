
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DELPRODUCT, GETPRODUCTS } from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import { useNavigate } from 'react-router-dom'
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import defaultImg from '../../assets/images/default.png'
import ChangeStock from "./ChangeStock";

const StockManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { productlist, productdeleted, totalSum } = useSelector(state => state.product);

    const customToast = "delPro";
    console.log(productlist, "fdsdfsefsdf")
    console.log(totalSum, "totalSum")

    const fetchProducts = () => {
        dispatch(GETPRODUCTS());
    };

    const [open, setOpen] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [datas, setDatas] = useState([])
    const [sum, setSum] = useState()
    const [stockData, setStockData] = useState()
    console.log(datas, "da")
    console.log(sum, "sum")

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    useEffect(() => {
        if (productlist) {
            setDatas(productlist)
        }
    }, [productlist])

    useEffect(() => {
        if (totalSum) {
            setSum(totalSum);
        }
    }, [totalSum]);



    const handleUpdate = (data) => {
        setOpen(true)
        setStockData(data)
    }


    useEffect(() => {
        if (productdeleted) {
            toast.success(" Product deleted successfully", { toastId: customToast });
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [productdeleted])

    useEffect(() => {
        // if(filterText){
        let datas = productlist?.filter(ord => ord?.name?.toLowerCase().includes(filterText?.toLowerCase()))
        setDatas(datas)
        // }
    }, [filterText])

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return (
            <div style={{ flex: 1 }}>
                <Row className="justify-content-end">
                    <Col xs="12" md="auto" className="text-md-right">
                        <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    </Col>
                </Row>
            </div>
        );
    }, [filterText, sum]);


    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        {row?.image[0] ? (<img style={{ width: 40, height: 40 }} src={`${row?.imageBasePath}${row?.image[0]}`}></img>) : (<img style={{ width: 40, height: 40 }} src={defaultImg}></img>)}
                    </span>
                </>
            )
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            sortable: true,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("unit")}</b>,
        //     header: <b>{Capitalize("unit")}</b>,
        //     selector: row => row?.units && row?.units?.map((res) => (
        //         <div>
        //             <span>{res?.name}</span>
        //         </div>

        //     )),
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("stock")}</b>,
            header: <b>{Capitalize("stock")}</b>,
            selector: row => row?.units?.[0]?.variants?.[0]?.stock ? row?.units?.[0]?.variants?.[0]?.stock : "-",
            sortable: true,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "update",
            accessor: (str) => "update",
            cell: (row, index) => (

                <>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={() => handleUpdate(row)}
                    >
                        Update
                    </Button>
                </>
            )
        }
    ];




    useEffect(() => {
        dispatch(GETPRODUCTS())
    }, [])

    if (!productlist) {
        return <div>Loading...</div>
    }

    const closeModal = () => {
        setOpen(false)
    }

    return (
        <Fragment>
            <Breadcrumb title="Products Stock List" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Product Details</h5>
                            </CardHeader>
                            <CardBody>

                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <Fragment>
                                        {productlist &&
                                            <DataTable
                                                pagination
                                                title=""
                                                columns={columns}
                                                data={datas}
                                                fixedHeader
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                            />}
                                    </Fragment>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ChangeStock openModal={open} onCloseModal={closeModal} fetchProducts={fetchProducts} stockData={stockData} />
            {/* <!-- Container-fluid Ends--> */}
        </Fragment>
    )
}

export default StockManagement